<!--  登录   -->
<template>
  <div class="container">
    <div class="logo">
      <div>
        <img src="../../static/images/my_index/logo_.png" alt="">
        <!-- <img src="https://passport.zhulong.com/img/mlogin/logo.png" alt=""> -->
      </div>
      <!-- <div>
        <img src="../../static/images/my_index/logo_text.png" alt="" class="logo_text">

      </div> -->
    </div>
    <div class="tab_login">
      <div
        v-if="is_num"
        class="tab_nav"
        :class="tabs ? 'is_tab' : ''"
        @click="account_login(2)"
      >
        账号登录
      </div>
      <div
        v-if="is_Captcha"
        class="tab_nav"
        :class="tabs1 ? 'is_tab' : ''"
        @click="account_login(1)"
      >
        验证码登录
      </div>
    </div>
    <div v-if="numer_login" class="numer_login">
      <div class="phone">
        <input
          v-model="num"
          type="text"
          placeholder="用户名/手机号/邮箱"
          @change="isshows()"
        >
      </div>
      <div class="password">
        <div v-show="is_pass">
          <input
            v-model="password"
            type="password"
            placeholder="请输入密码"
            @change="isshows()"
          >
        </div>
        <div v-show="is_code">
          <input v-model="password" type="text" placeholder="请输入密码">
        </div>
      </div>
    </div>
    <div v-if="code_login" class="code_login">
      <div class="phone">
        <div class="diqu">
          <input v-model="guoji" type="text">
          <!-- <img src="../../assets/down_t.png" alt="" /> -->
        </div>
        <input
          v-model="phone"
          style="background: #fff !important"
          class="phone_inp"
          type="text"
          placeholder="请输入手机号"
        >
      </div>
      <div class="code_box">
        <div class="code_m">
          <input v-model="ucode" type="text" placeholder="请输入验证码">
        </div>
        <div v-show="iscode" class="get_code" @click="getcode()">
          {{ btnText }}
        </div>
        <div v-show="againcode" class="get_code">
          {{ code_text }}
        </div>
      </div>
    </div>
    <div
      v-show="isShowBtn"
      class="login_btn"
      :disabled="isdisabled"
      @click="sign_in(1)"
    >
      登录
    </div>
    <div
      v-show="!isShowBtn"
      class="login_btn_yzm"
      :disabled="isdisabled"
      @click="sign_in(2)"
    >
      登录/注册
    </div>
    <div class="forget_pass"><span @click="forgetPassword">忘记密码</span></div>
    <!-- <div v-show="isShowBtn" class="register" @click="register()">注册新账号</div> -->
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
import { signIn, wx_getphoneCode, sendMobileCode, loginByUserName, loginByPhone } from '@/api/login.js'
import { Toast } from 'vant'
export default {
  data() {
    return {
      isShowBtn: true,
      tabs: true,
      btnText: '获取验证码',
      tabs1: false,
      againcode: '',
      is_code: '',
      password: '',
      active: true,
      is_pass: true,
      phone: '',
      guoji: '+86',
      numer_login: true,
      code_login: false,
      type: 2,
      num: '',
      ucode: '',
      iscode: true,
      is_num: true,
      is_Captcha: true,
      is_qiye: true,
      code_text: '111',
      isdisabled: false,
      randstr: '',
      ticket: '',
      timer: null,
      popup_id: 0,
      redirect: ''
    }
  },
  created() {
    this.popup_id = this.$route.query.popup_id
    // if (decodeURIComponent(this.$route.query.redirect) === 'undefined') {
    //   this.redirect = 'https://m.zhulong.com'
    // } else {
    //   this.redirect = decodeURIComponent(this.$route.query.redirect)
    // }
    // this.redirect = decodeURIComponent(this.$route.query.redirect) === 'undefined' ? 'https://m.zhulong.com' : decodeURIComponent(this.$route.query.redirect)

    // console.log(this.redirect)
    // this.sign_in()
  },
  mounted() {
    this.isshows()
    this.getParams()
  },
  methods: {
    getParams() {
      if (decodeURIComponent(this.$route.query.redirect) === 'undefined') this.redirect = window.location.origin
      else {
        const urlArr = decodeURIComponent(window.location.href).split('?')
        console.log(urlArr)
        let paramsStr = ''
        for (let index = 1; index < urlArr.length; index++) {
          const element = urlArr[index]
          if (index === 1) {
            paramsStr += element.split('=')[1]
          } else {
            paramsStr += '?' + element
          }
        }
        this.redirect = paramsStr
      }
      console.log(this.redirect)
    },
    getcode() {
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (!reg.test(this.phone)) {
        Toast('手机号格式有误')
        return false
      }
      this.sendYzm()
    },
    down_() {
      this.timer = setInterval(() => {
        this.btnText--
        if (this.btnText == 1) {
          clearInterval(this.timer)
          this.timer = null
          this.btnText = '重发验证码'
        }
      }, 1000)
    },
    sendYzm() {
      // var params = {
      //   type: 1,
      //   account_name: this.phone,
      //   platform_code: 5
      // }
      var params = {
        type: 1,
        mobile: this.phone,
        // ticket: this.ticket,
        // randstr: this.randstr,
        code_type: 1
      }
      // wx_getphoneCode(params).then((res) => {
      sendMobileCode(params).then((res) => {
        if (res.errNo === 0) {
          this.btnText = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    isshows() {
      if (this.password.length !== 0 && this.num.length !== 0) {
        this.isdisabled = true
      }
    },
    encrypt(msg) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrWVnSOu9m7O8X3taQGXzVlB9B0Gw1Mvbc0MxKZOxT8SlQVB1Krpu3KfuoxgGE1TikX/JkYJf+D4tTqENqk5pnSZc784gWZPEs2O+uz5R/8Ay8qP06uHDzw1oGDrpo8wxWQ7Ae2IwE2gTDtpcyg8NUJp14uYwsvA47iDpXHGmPxQIDAQAB-----END PUBLIC KEY-----'
      )
      return encrypt.encrypt(msg)
    },
    // 账号登陆与   &&  验证码登录切换/user/logon/signIn
    account_login(type) {
      this.type = type
      if (type === 1) {
        this.tabs1 = true
        this.tabs = false
        this.code_login = true
        this.numer_login = false
        this.isShowBtn = false
      } else {
        this.tabs = true
        this.tabs1 = false
        this.numer_login = true
        this.code_login = false
        this.isShowBtn = true
      }
      // this.active = !this.active
    },

    // 登录
    sign_in(type) {
      // console.log(this.num)
      // 账号密码登录
      if (type === 1) {
        if (!this.num) {
          Toast('请输入手机号')
          return
        }
        if (!this.password) {
          Toast('请输入密码')
          return
        }
        // var params = {
        //   account_name: this.num,
        //   password: this.encrypt(this.password),
        //   type: 2
        // }
        // signIn(params).then((res) => {
        var params = {
          loginname: this.num,
          password: this.password,
          from_url: 'wap',
          type: 2,
          agency_id: 2
        }
        loginByUserName(params).then(res => {
          if (res.errNo == 160 || res.errNo == '160') {
            Toast('需要绑定手机号')
            this.$router.push({
              path: '/set_up/addPhone',
              query: {
                account_name: this.num,
                password: this.encrypt(this.password)
              }
            })
            return false
          }
          if (res.errNo !== 0) {
            Toast(res.msg)
          }
          if (this.popup_id && res.errNo === 0) {
            window.location.href = `https://m.zhulong.com/interview/#/hrresume?id=${this.popup_id}`
            return false
          }
          if (res.errNo === 0) {
            // window.location.href = `http://openwww.zhulong.com/interview/#/hrresume?id=${this.popup_id}`
            window.location.href = this.redirect && this.redirect != undefined ? this.redirect : window.location.origin
          }
        })
      } else {
        if (!this.phone) {
          Toast('请输入手机号')
          return
        }
        if (!this.ucode) {
          Toast('请输入验证码')
          return
        }
        // params = {
        //   account_name: this.phone,
        //   code: this.ucode,
        //   type: 1,
        //   platform_code: 5
        // }
        // wx_getphoneCode(params).then((res) => {
        params = {
          mobile: this.phone,
          code: this.ucode,
          agency_id: 2,
          type: 1,
          from_url: 'wap'
        }
        loginByPhone(params).then(res => {
          if (res.errNo !== 0) {
            Toast(res.msg)
          } else {
            const result = res.result ? res.result : {}
            const type = result.type ? result.type : ''
            if (type === 'reg') {
              // 增加新用户注册
              this.newRegistInfo()
            } else {
              window.location.href = this.redirect && this.redirect != undefined ? this.redirect : window.location.origin
            }
          }
        })
      }
    },
    // 新注册信息页面
    newRegistInfo() {
      const redirect = this.redirect && this.redirect != undefined ? this.redirect : window.location.origin
      this.$router.push({ path: '/regInfo', query: { redirect: redirect }})
    },
    // 跳转到注册页面
    register() {
      this.$router.push({ path: '/login/register' })
    },
    forgetPassword() {
      this.$router.push({ path: '/changePassword', query: { forget: 1 }})
    }
  }
}
</script>

<style lang='scss' scoped>

* {
  box-sizing: content-box;
}
.container {
  width: 100%;
  overflow: hidden;
  .logo {
    width: 100%;
    // margin: 0 auto;
    margin-top: 190px;
    text-align: center;
    img {
      width:  220px;
    }
    .logo_text{
      margin-top: -90px;
  width: 220px;
}
  }
  .tab_login {
    width: 92%;
    margin: 0 4%;
    height: 65px;
    display: flex;
    margin-top: 40px;
    line-height: 45px;
    font-size: 36px;
    justify-content: space-around;
    border-bottom: 1px solid #dcdcdc;
    .is_tab {
      border-bottom: 4px solid #ee2e2e;
    }
  }
  .numer_login {
    width: 92%;
    margin: 0 4%;
    margin-top: 55px;
    height: 177px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    .phone {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      input {
        width: 80%;
        margin: 0 5%;
      }
    }
    .password {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      width: 100%;
      input {
        width: 80%;
        margin: 0 5%;
      }
      .eyes {
        width: 30px;
        line-height: 88px;
        vertical-align: middle;
      }
    }
  }
  .code_login {
    width: 92%;
    margin: 0 4%;
    margin-top: 55px;
    height: 177px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    .phone {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      .diqu {
        width: 150px;
        float: left;
        border-right: 3px solid #dcdcdc;
        overflow: hidden;
        margin-top: 25px;
        height: 40px;
        input {
          float: left;
          width: 50%;
          font-size: 30px;
          margin-left: 20px;
          height: 40px;
          line-height: 4%;
        }
        img {
          float: left;
          width: 26px;
          margin-top: 15px;
        }
      }
      .phone_inp {
        width: 50%;
        height: 50px;
        line-height: 50px;
        margin-top: 20px;
        margin-left: 5%;
      }
    }
    .code_box {
      .code_m {
        width: 70%;
        float: left;
        height: 70px;
        margin-top: 10px;
        border-right: 2px solid #dcdcdc;
        input {
          line-height: 70px;
          margin-left: 20px;
          font-size: 32px;
        }
      }
      .get_code {
        float: left;
        color: #ee2e2e;
        font-size: 32px;
        width: 28%;
        text-align: center;
        line-height: 88px;
      }
    }
  }
  .login_btn {
    height: 88px;
    width: 92%;
    margin: 0 4%;
    background: #ee2e2e;
    border-radius: 10px;
    line-height: 90px;
    font-size: 32px;
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }
  .login_btn_yzm {
    height: 88px;
    width: 92%;
    margin: 0 4%;
    background: #ee2e2e;
    border-radius: 10px;
    line-height: 90px;
    font-size: 32px;
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }
  .register {
    width: 92%;
    margin: 0 4%;
    line-height: 40px;
    margin-top: 40px;
    text-align: center;
    color: #999999;
    font-size: 28px;
  }
}
input:-internal-autofill-selected {
  background: #fff !important;
}
.forget_pass{
  text-align: center;
  color: #ee2e2e;
  line-height: 40px;
  padding-top: 30px;
}
</style>
